import { useState } from 'react';
import './InstaPlayer.css';

function InstaPlayer() {
    const [visible, setVisible] = useState('');

    window.playerLoadedCallback = function () {
        visible ? setVisible('') : setVisible('loader isLoaded');
        console.log("Player Loaded");
    }

    return (
        <div className="player-wrapper">
            <div className="instaPlayer">
                <div className={visible || 'loader isLoading'}>
                    <div className="loading"></div>
                </div>
            </div>
        </div>
    )
}

export default InstaPlayer;