import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App';

WebFont.load({
  google: {
    families: ['Roboto']
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
