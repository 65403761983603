import { React } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PlayerDashboard from './components/pages/Dashboard/PlaybackDashboard';
import AdsDashboard from './components/pages/Dashboard/AdsDashboard';

function IndexRouter(props) {
    return(
        <Router>
            <Switch>
                <Route exact path="/" render={(props) => <PlayerDashboard /> } />
                <Route exact path="/ads-tester" render={(props) => <AdsDashboard /> } />
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

export default IndexRouter;