import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack'; 
import Header from '../../reusables/Header/Header';
import Player from '../Player/Player';
import PlaybackFieldInput from '../../reusables/FieldInput/PlaybackFieldInput';
import MuiSnackbar from '../../reusables/Snackbar/MuiSnackbar';
import './Dashboard.css';

function PlaybackDashboard(props) {
    const [contentType, setContentType] = useState('dash');
    const [contentURL, setContentURL] = useState('https://bitmovin-a.akamaihd.net/content/sintel/sintel.mpd');
    const [licenseURL, setLicenseURL] = useState('');
    const [customData, setCustomData] = useState('');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [message, setMessage] = useState('');
    const [PiP, setPiP] = useState(false);
    
    function getPlaybackURL(mediaTypeInput, contentInput, licenseInput, customInput) {
        if (contentInput.length > 0) {
            setContentType(mediaTypeInput);
            setContentURL(contentInput);
        } else {
            setOpen(true);
            setMessage("Playback url can't be empty");
            setSeverity("error");
        }
        setLicenseURL(licenseInput);
        setCustomData(customInput);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    function handlePiP() {
        if (window && window.InstaPlayer) {
            if (PiP) {
                window.InstaPlayer.disablePiP();
                setPiP(false);
            } else {
                window.InstaPlayer.enablePiP();
                setPiP(true);
            }
        }
    };

    function handleRate(e) {
        if (e.target.innerText === "0.5x") {
            window.InstaPlayer.setPlaybackRate(0.5);
        } else if (e.target.innerText === "1.5x") {
            window.InstaPlayer.setPlaybackRate(1.5);
        } else if (e.target.innerText === "2x") {
            window.InstaPlayer.setPlaybackRate(2);
        } else {
            window.InstaPlayer.setPlaybackRate(1);
        }
    }

    return(
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-lg-12 text-center">
                        <h3 className='title'>Playback Tester</h3>
                    </div>
                </div>
                <div className="row mt-lg-5 mb-lg-5">
                    <div className="col-lg-6">
                        { <PlaybackFieldInput getPlaybackURL={getPlaybackURL} /> }
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-4">
                        <Player
                            contentType={contentType}
                            contentURL={contentURL}
                            licenseURL={licenseURL}
                            customData={customData}
                        />
                        <div className="row mt-4">
                            <div className="col-lg-3">
                                <Button onClick={handlePiP} variant="contained">{PiP ? 'Disable PiP' : 'Enable PiP'}</Button>
                            </div>
                            <div className="col-lg-9 d-flex justify-content-end">
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Chip label="Playback Rate" />
                                    <Chip value="0.5" label="0.5x" color="primary" variant="outlined" size="small" onClick={handleRate} />
                                    <Chip label="Normal" color="primary" variant="outlined" size="small" onClick={handleRate} />
                                    <Chip label="1.5x" color="primary" variant="outlined" size="small" onClick={handleRate} />
                                    <Chip label="2x" color="primary" variant="outlined" size="small" onClick={handleRate} />
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MuiSnackbar open={open} message={message} severity={severity} handleClose={handleClose} />
        </>
    )
}

export default React.memo(PlaybackDashboard);