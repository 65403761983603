import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import SaranyuLogo from '../../../assets/images/logo.png';

function Header(props) {

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: 'black' }}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="#">
                    <div className='d-flex flex-row align-items-center'>
                        <img style={{ height: '40px', display: 'block' }} src={SaranyuLogo} alt="Saranyu"></img>
                        <h5 style={{ color: '#F5F5F5', margin: 0, marginLeft: 25 }}>InstaPlayer Demo v4.0.0</h5>
                    </div>
                </Link>
                <ul className="navbar-nav ml-auto mr-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Playback Tester</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/ads-tester">Ads Tester</Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/shorts-player">Shorts Player</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default React.memo(Header);