import { React } from 'react';
import IndexRouter from './IndexRouter';
import './App.css';

function App() {
  return (
    <>
      <IndexRouter />
    </>
  );
}

export default App;
