import React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InfoIcon from '@mui/icons-material/Info';

function AdsFieldInput(props) {
    const [AdType, setAdType] = useState('preroll');
    const [ClientType, setClientType] = useState('csai');
    const [SSAURL, setSSAURL] = useState('https://171f6efea34043088c4d6017e788915a.mediatailor.eu-west-1.amazonaws.com/v1/master/17c705d483d32a0dc3058c7390c4e299d563b47c/episode3/playlist.m3u8');
    const [PrerollAdURL, setPrerollAdURL] = useState('');
    const [MidrollAdURL, setMidrollAdURL] = useState('');
    const [PostrollAdURL, setPostrollAdURL] = useState('');
    const [MidrollAdSchedule, setMidrollAdSchedule] = useState('');
    let AdField = "";
   
    if (AdType === "preroll") {
        AdField = (
            <div className="row mt-3">
                <div className="col-lg-12">
                    <FormControl fullWidth>
                        <InputLabel htmlFor="Pre Roll Ad">Pre Roll</InputLabel>
                        <OutlinedInput
                            type="text"
                            name="Pre Roll Ad"
                            autoComplete="off"
                            required
                            value={PrerollAdURL}
                            label="Pre Roll Ad"
                            onInput={e => setPrerollAdURL(e.target.value)}
                        />
                    </FormControl>
                </div>
            </div>
        )
    } else if (AdType === "midroll") {
        AdField = (
            <>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="Mid Roll Ad">Mid Roll</InputLabel>
                            <OutlinedInput
                                type="text"
                                name="Mid Roll Ad"
                                autoComplete="off"
                                value={MidrollAdURL}
                                label="Mid Roll Ad"
                                onInput={e => setMidrollAdURL(e.target.value)}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="Ad Schedule">Ad Schedule</InputLabel>
                            <OutlinedInput
                                type="text"
                                name="Ad Schedule"
                                autoComplete="off"
                                label="Ad Schedule"
                                onInput={e => setMidrollAdSchedule(e.target.value)}
                            />
                        </FormControl>
                    </div>
                </div>
            </>
        )
    } else if (AdType === "postroll") {
        AdField = (
        <div className="row mt-3">
            <div className="col-lg-12">
                <FormControl fullWidth>
                    <InputLabel htmlFor="Post Roll Ad">Post Roll</InputLabel>
                    <OutlinedInput
                        type="text"
                        name="Post Roll Ad"
                        autoComplete="off"
                        required
                        value={PostrollAdURL}
                        label="Post Roll Ad"
                        onInput={e => setPostrollAdURL(e.target.value)}
                    />
                </FormControl>
            </div>
        </div>
        )
    }
    
    const handleSampleClick = () => {
        let SampleAd = "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=";
        if (AdType === "preroll") {
            setPrerollAdURL(SampleAd);
            setMidrollAdURL("");
            setPostrollAdURL("");
        } else if (AdType === "midroll") {
            setPrerollAdURL("");
            setMidrollAdURL(SampleAd);
            setPostrollAdURL("");
        } else if (AdType === "postroll") {
            setPrerollAdURL("");
            setMidrollAdURL("");
            setPostrollAdURL(SampleAd);
        }
    }

    const handleAdInsertion = () => {
        props.getAdDetails(PrerollAdURL, MidrollAdURL, MidrollAdSchedule, PostrollAdURL);
    }

    const handleSSAI = () => {
        props.getSSAI(SSAURL);
    }

    return (
        <>
            <Paper sx={{ padding: '15px' }}>
                <div className='d-flex'>
                    <FormControl fullWidth>
                        <RadioGroup defaultValue='csai' row>
                            <FormControlLabel
                                value="ssai"
                                control={<Radio />}
                                label="Server Side Ads (SSAI)"
                                onClick={e => setClientType(e.target.value)}
                            />
                            <FormControlLabel
                                value="csai"
                                control={<Radio />}
                                label="Client Side Ads (CSAI)"
                                onClick={e => setClientType(e.target.value)}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                { "csai" === ClientType ? (<Paper sx={{ padding: '15px', marginTop: '15px', background: '#FAFAFA' }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-3">
                            <Chip label="Ads Config" color="primary" variant="outlined" />
                        </div>
                        <div className="col-lg-9">
                            <FormControl fullWidth>
                                <RadioGroup defaultValue='preroll' row>
                                    <FormControlLabel
                                        value="preroll"
                                        control={<Radio />}
                                        label="Pre Roll"
                                        onClick={e => setAdType(e.target.value)}
                                    />
                                    <FormControlLabel
                                        value="midroll"
                                        control={<Radio />}
                                        label="Mid Roll"
                                        onClick={e => setAdType(e.target.value)}
                                    />
                                    <FormControlLabel
                                        value="postroll"
                                        control={<Radio />}
                                        label="Post Roll"
                                        onClick={e => setAdType(e.target.value)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-2 d-flex align-items-center">
                        <div className="col-lg-4">
                            <Chip
                                icon={<InfoIcon />}
                                label="Use Sample Ad"
                                color="primary"
                                onClick={handleSampleClick} 
                            />
                        </div>
                    </div>
                    { AdField }
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <Button
                                onClick={handleAdInsertion}
                                startIcon={<PlayCircleIcon />}
                                variant="contained">Apply</Button>
                        </div>
                    </div>
                </Paper>) : (
                    <>
                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="ssaiurl">Content URL</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        name="ssaiurl"
                                        autoComplete="off"
                                        value={SSAURL}
                                        label="Content URL"
                                        onInput={e => setSSAURL(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row mt-3">
                        <div className="col-lg-12">
                            <Button
                                onClick={handleSSAI}
                                startIcon={<PlayCircleIcon />}
                                variant="contained">Play SSA Content</Button>
                        </div>
                    </div>
                    </>
                ) }
                { "csai" === ClientType ? (<div className="mt-3 mb-3">
                    <Alert severity="warning">Video will be <b>autoplayed</b> for Pre-Roll Ads.</Alert>
                </div>) : "" }
                <div className="mt-3 mb-3">
                    <Alert severity="info">Click Player's Play button to see Ads in action.</Alert>
                </div>
            </Paper>
        </>
    )
}

export default AdsFieldInput;