import React from 'react';
import { useState } from 'react'; 
import Header from '../../reusables/Header/Header';
import Player from '../Player/Player';
import AdsFieldInput from '../../reusables/FieldInput/AdsFieldInput';
import MuiSnackbar from '../../reusables/Snackbar/MuiSnackbar';
import './Dashboard.css';

function AdsDashboard(props) {
    const [contentURL, setContentURL] = useState('https://bitmovin-a.akamaihd.net/content/sintel/sintel.mpd');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [message, setMessage] = useState('');
    const [adConfig, setadConfig] = useState('');
    
    function getAdDetails(PrerollAdURL, MidrollAdURL, MidrollAdSchedule, PostrollAdURL) {
        setContentURL('https://bitmovin-a.akamaihd.net/content/sintel/sintel.mpd');
        let adObj = [];
        if (PrerollAdURL.length > 0) {
            let adSet = {
                "adurl": PrerollAdURL, // prerole ad
                "schedule": "preroll"
            }
            adObj.push(adSet);
        } else if (PostrollAdURL.length > 0) {
            let adSet = {
                "adurl": PostrollAdURL, // postrole ad
                "schedule": "postroll"
            }
            adObj.push(adSet);
        } else if (MidrollAdURL.length > 0) {
            if (MidrollAdSchedule > 0) {
                let adSet = {
                    "adurl": MidrollAdURL, // midrole ad
                    "schedule": Number(MidrollAdSchedule)
                }
                adObj.push(adSet);
            } else if (MidrollAdSchedule <= 0){
                setOpen(true);
                setSeverity("error");
                setMessage("Are you trying to set Pre-Roll Ads ?");
            } else {
                setOpen(true);
                setSeverity("error");
                setMessage("Ad Schedule must be a number value");
            }
        }
        setadConfig(adObj);
    }

    function getSSAI(SSAURL) {
        setContentURL(SSAURL);
        setadConfig('');
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return(
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-lg-12 text-center">
                        <h3 className='title'>Ads Tester</h3>
                    </div>
                </div>
                <div className="row mt-lg-5 mb-lg-5">
                    <div className="col-lg-6">
                        {<AdsFieldInput getSSAI={getSSAI} getAdDetails={getAdDetails} /> } 
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-4">
                        <Player
                            contentURL={contentURL}
                            adConfig={adConfig}
                        />
                    </div>
                </div>
            </div>
            <MuiSnackbar open={open} message={message} severity={severity} handleClose={handleClose} />
        </>
    )
}

export default React.memo(AdsDashboard);