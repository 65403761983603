import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MuiSnackbar(props) {

    const { open, message, severity, handleClose } = props;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={ handleClose }>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>{ message }</Alert>
        </Snackbar>
    )
}

