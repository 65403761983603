import React from 'react';
import { useState, useEffect } from 'react';
import InstaPlayer from '../../reusables/InstaPlayer/InstaPlayer';
import defaultPoster from '../../../assets/images/poster.png';

function Player(props) {
    const [playerConfig, setPlayerConfig] = useState('');
    const contentType = props.contentType;
    const contentURL = props.contentURL;
    const licenseURL = props.licenseURL;
    const customData = props.customData;
    const adConfig   = props.adConfig;

    window.registerCallBacks = function () {
        if (window.InstaPlayer.eventcallbacks) {
            window.InstaPlayer.eventcallbacks.onPlay = function () { 
                console.log("Event Fired: onPlay");
            };
            window.InstaPlayer.eventcallbacks.onPause = function () {
                console.log("Event Fired: onPause");
            };
            window.InstaPlayer.eventcallbacks.onResume = function () { 
                console.log("Event Fired: onResume");
            }
            window.InstaPlayer.eventcallbacks.onBackwardSeek = function () { 
                console.log("Event Fired: onBackwardSeek");
            };
            window.InstaPlayer.eventcallbacks.onForwardSeek = function () { 
                console.log("Event Fired: onForwardSeek");
            };
            window.InstaPlayer.eventcallbacks.onSeeked = function () { 
                console.log("Event Fired: onSeeked");
            };
            window.InstaPlayer.eventcallbacks.onComplete = function () { 
                console.log("Event Fired: onComplete");
            };
            window.InstaPlayer.eventcallbacks.onBuffering = function () { 
                console.log("Event Fired: onBuffering");
            };
            window.InstaPlayer.eventcallbacks.onBack = function () { 
                alert("Leaving InstaPlayer?");
                window.history.back();
            };
            window.InstaPlayer.eventcallbacks.onError = function (type, code) {
                switch (code) {
                    case 25:
                        console.log("DASH Manifest Download Failed");
                        break;
                    case 26:
                        console.log("FairPlay Video Generic Error");
                        break;
                    case 27:
                        console.log("DASH Segment Loading Failed");
                        break;
                    case 111:
                        console.log("No Widevine License Server URL Specified");
                        break;
                    case 114:
                        console.log("Widevine DRM License Error");
                        break;
                    case 115:
                        console.log("FairPlay DRM License Error");
                        break;
                    default:
                        console.log("Error: ", type, code);
                }
            }
        }
        return
    }

    useEffect(() => {
        if (window.SaranyuInstaPlayer) {
            let playerConfig = window.SaranyuInstaPlayer(contentType, '.instaPlayer', {
                'file': [{
                    'content_url': contentURL,
                    'encryption': true,
                    'drm': {
                        'widevine': {
                            'LA_URL': licenseURL,
                            'CUSTOM_DATA': customData
                        }
                    },
                    'videotitle': 'Saranyu InstaPlayer',
                    'seekonload': 0,
                    'poster': defaultPoster
                }],
                'autoplay': false,
                'mute': false,
                'hideControlsWhenInactive': true,
                'hideControlsTimeout': 3000,
                'qualityswitch': { 'label': 'auto', 'metric': 'resolution' },
                'advertisement': {
                    'cue': true,
                    'adsets': adConfig
                },
                'features': ['backbutton','title','playpause','backwardSeek','forwardSeek','bigicons','volume','progressbar','time','subtitles','multiaudio','qualityswitch','fullscreen','encryption','eventcallbacks','advertisement'],
                'registerCallBacks': window.registerCallBacks
            });

            setPlayerConfig(playerConfig);

        }
    }, [contentType, contentURL, licenseURL, customData, adConfig])

    return (
        <InstaPlayer config={playerConfig} />
    )
}

export default Player;