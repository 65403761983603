import React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

function PlaybackFieldInput(props) {
    const [mediaType, setMediaType] = useState('dash');
    const [contentInput, setContentInput] = useState('');
    const [licenseInput, setLicenseInput] = useState('');
    const [customDataInput, setCustomDataInput] = useState('');

    const handlePlayback = () => {
        props.getPlaybackURL(mediaType, contentInput, licenseInput, customDataInput);
    }

    return (
        <>
            <Paper sx={{ padding: '15px' }}>
                <Paper sx={{ padding: '15px', marginTop: '15px', background: '#FAFAFA' }}>
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-4">
                            <Chip label="Playback Details" color="primary" variant="outlined" />
                        </div>
                        <div className="col-lg-8">
                            <FormControl fullWidth>
                                <RadioGroup defaultValue='dash' row>
                                    <FormControlLabel
                                        value="hls"
                                        control={<Radio />}
                                        label="HLS"
                                        onClick={e => setMediaType(e.target.value)}
                                    />
                                    <FormControlLabel
                                        value="dash"
                                        control={<Radio />}
                                        label="DASH"
                                        onClick={e => setMediaType(e.target.value)}
                                    />
                                    <FormControlLabel
                                        value="mp4"
                                        control={<Radio />}
                                        label="MP4"
                                        onClick={e => setMediaType(e.target.value)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <FormControl fullWidth>
                                <InputLabel htmlFor="contentURL">* Content URL</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    name="contentURL"
                                    autoComplete="off"
                                    required
                                    label="contentURL"
                                    onInput={e => setContentInput(e.target.value)}
                                />
                            </FormControl>
                        </div>
                    </div>
                    { "dash" === mediaType ? (
                        <>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="contentURL">License URL</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            name="licenseURL"
                                            autoComplete="off"
                                            label="licenseURL"
                                            value={licenseInput}
                                            onInput={e => setLicenseInput(e.target.value)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-12">
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="customData">Custom Data</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            name="customData"
                                            autoComplete="off"
                                            required
                                            label="customData"
                                            onInput={e => setCustomDataInput(e.target.value)}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </>
                    ) : "" }
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <Button
                                onClick={handlePlayback}
                                startIcon={<PlayCircleIcon />}
                                variant="contained">Play</Button>
                        </div>
                    </div>
                </Paper>
            </Paper>
        </>
    )
}

export default PlaybackFieldInput;